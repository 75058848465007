html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}
